import React from 'react'
import { Link, Redirect } from 'react-router-dom'

const Footer = () => {
    return(
        <div id="footer">
            <span><em>&copy;2019 SEEN | STL — <Link to="/privacy">Privacy Policy</Link></em></span>
        </div>
    )
}

export default Footer