import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { sendVerify } from '../../store/actions/authActions'


export class Verify extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.sendVerify();
    this.props.history.push("/emailreminder");
  } 

  render() {
    const { auth, authError} = this.props;
    if (auth.emailVerified === true) return <Redirect to='/createevent' />
    if ( !auth.uid ) return <Redirect to="/createevent" />

    return (
      <div className="info-container">
        <h1>Verify Your Email Address&nbsp;<i className="material-icons alert-icon">warning</i> </h1>
        <p className="verification-alert"> You've registered but need still need to verify your email address to post for your organization.</p>
        <p className="verif-instructions">Click the "RESEND LINK" button to send new verification instructions to your email inbox.</p>
        
        <form onSubmit={this.handleSubmit}>
          <div className="input-field">
            <button>Resend Link</button>
          </div>
        </form>
        <p className="verif-instructions"><em>Already Verified?</em> Click the <i class="material-icons refresh-icon">refresh</i> reload button on you browser to load you account info.</p>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      sendVerify: () => dispatch(sendVerify())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Verify)
