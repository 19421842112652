import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'

const ShareSuccess = ({keyPass}) => {
    if (keyPass !== "go&s33") return <Redirect to="/" />
    return(
        <div className="info-container">
            <p><span className="success">SUCCESS!</span> Your selections have been shared.</p>
            <Link to="/">Back to listings</Link>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      keyPass: state.privateMode.accessKey   
    }
  }
export default connect(mapStateToProps)(ShareSuccess)