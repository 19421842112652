import React, { Component } from 'react'
import { Redirect, Link, withRouter } from 'react-router-dom'
import { signOut } from '../../store/actions/authActions'
import { connect } from 'react-redux'



export class SignUpSuccess extends Component {

  render() {
    const { profile } = this.props;
    
    if(profile.emailAddress){ 
      setTimeout(() => {
        this.props.signOut();
        this.props.history.push('/');  
      }, 10000);
    }

    return(
        <div className="info-container">
            <p><span className="success">THANKS!</span> Check your email— We've sent you a message to complete your registration and sign in.</p>
            <p><span className="not-registered">No link? Be sure to <Link to="/signup">register</Link>  with a valid email address first.</span></p>
            <Link to="/">Back to listings</Link>
        </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignUpSuccess))