  const initState = {
      favorites: []
  }
  
  const favoriteReducer = (state = initState, action) => {
    switch (action.type){  
    case 'ADD_FAVORITE': {
        return {
            ...state, 
            favorites: state.favorites.concat( action.favorites )
        }
    }
    case 'REMOVE_FAVORITE': {
        return {
            ...state,
            favorites: state.favorites.filter(function(fav){ 
                return fav !== action.favorites; 
          })
        }
    }
    case 'EMPTY_FAVORITE': {
        return {
            ...state, 
            favorites: []
        }
    }
    default:   
        return state   
    }
}
  
export default favoriteReducer