import React, { Component } from 'react'
import { addFavorite } from '../../store/actions/favoriteActions'
import { connect } from 'react-redux'

class Add extends Component {
  
  //favorites and events states are imported below.
  handleClick = (e) => {
      e.preventDefault();
      this.props.addEmailedFavs(this.props.eventId);
  }
  
  render() {
  
    return(
        <div className="fav-add" onClick={this.handleClick}>
            <a href="" data-choice={ this.props.eventId }>+</a>
        </div>    
        )
    }
  }
  const mapStateToProps = (state) => {
      return{
          favorites: state.favorite
      }
  } 

const mapDispatchToProps = () => {
  return {
      addFavorite
  }
}
export default connect(mapStateToProps, mapDispatchToProps())(Add)
