import React, { Component } from 'react'
import { Redirect, Link } from 'react-router-dom'
import { signOut } from '../../store/actions/authActions'
import { connect } from 'react-redux'



export class SignInSuccess extends Component {

  render() {

    const { auth, authError, keyPass, profile } = this.props;

    return(
        <div className="info-container">
            <p><span className="success">THANKS!</span> If you're already a registered user, check your email inbox for a message and link from SEEN STL to securely sign in.</p>
            <p><span className="not-registered">No link in your inbox? Be sure you <Link to="/signup">register</Link>  with a valid email address first.</span></p>
            <Link to="/">Back to listings</Link>
        </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    profile: state.firebase.profile,
    keyPass: state.privateMode.accessKey
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInSuccess)