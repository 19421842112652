import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

const Support = () => {
    return(
        <div className="info-container">
            <h1>SUPPORT SEEN | STL</h1>
            <p>We're currently looking at resources to sustain ongoing administration, maintenance, and development of SEEN | STL.</p>
            <p>If you find this tool useful and would like to support its ongoing mission please contact: <a href="mailto: info@seenstl.com">info@seenstl.com</a></p>
            <div className="backlink">
               <Link to="/">Back to listings</Link>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      keyPass: state.privateMode.accessKey
    }
  }
export default connect(mapStateToProps)(Support)