import React, { Component } from 'react'
import { connect } from 'react-redux'
import { noPassSignIn, doEmailCheck } from '../../store/actions/authActions'
import { Redirect, withRouter  } from 'react-router-dom'
import { Link } from 'react-router-dom'



export class SignIn extends Component {
  state = {
    email: ''
  }

  handleChange = (e) =>{
    this.setState({
        [e.target.id]: e.target.value
    })   
  }
  handleSubmit = (e) =>{
    e.preventDefault();
    this.props.noPassSignIn(this.state);
    this.props.history.push('/signinsuccess');
  } 

  render() {
    const { authError, auth, keyPass } = this.props;
    if(!auth.uid){
      this.props.doEmailCheck();
    }
  
    if (auth.uid && (auth.emailVerified === true)) return <Redirect to="/createevent" />
  
    return (
      <div className="form-container">
  
        <form onSubmit={this.handleSubmit}>
          <h1>Send Email Verification Link</h1>
          <p>Registered users must verify their active email account to post new events on behalf of their organization. If you know you've already registered enter your email address below and we'll send you a message with the verification link you need.</p>
          
          <div className="input-field">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" onChange={this.handleChange}/>
          </div>

          <div className="input-field auth-choice">
            <button>Send Link</button>
            <div className="red-text center">
              { authError ? <span id="sign-in-error">{ authError }, please register or reset your password below.</span> : null }
            </div>
            
            <span className="not-registered">Don't have an account? <Link to="/signup">Register Here</Link> </span>
         
          </div>
        </form>
       
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return{
    authError: state.auth.authError,
    auth: state.firebase.auth,
    keyPass: state.privateMode.accessKey   
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    noPassSignIn: (creds) => dispatch(noPassSignIn(creds)),
    doEmailCheck: () => dispatch(doEmailCheck())

  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))
