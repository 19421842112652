import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'

const ComingSoon = ({keyPass}) => {
    if (keyPass !== "go&s33") return <Redirect to="/" />
    return(
        <div className="info-container">
            <h1>Coming Soon...</h1>
            <p>SEEN STL will allow organizations to post their own events directly in the near future. This version of the app is a prototype to test and share the concept with its intended community.</p>
            
            <div className="backlink">
              <Link to="/">Back to listings</Link>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      keyPass: state.privateMode.accessKey   
    }
  }
  export default connect(mapStateToProps)(ComingSoon)