import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import moment from 'moment'
import ConvertTime from '../../helpers/ConvertTime'
import EditEvent from '../events/EditEvent'    

const EventDetails = (props) => {
      
      const { event, auth, id } = props;
      const editArea = <EditEvent myeventcontent={event} myeventid={id}/>
      if (event) {
        return(
        <div className="container section event-details">
        <h1>Current Listing Information:</h1>
        <div className="card z-depth-0">
           <div className="card-content">
           
             <h2 className="card-title">{ event.title }</h2>
             <p><span className="curr-list-type">Date:</span> { event.eventDate }</p>
             <p><span className="curr-list-type">Scheduled Time:</span> { ConvertTime(event.timeStart)  } - { ConvertTime(event.timeEnd) } </p>
             <p><span className="curr-list-type">Description:</span> { event.description }</p>
             <p><span className="curr-list-type">Location Name:</span> <i>{ event.locationName }</i></p>
             <p><span className="curr-list-type">Full Address:</span> { event.fullAddress }</p>
             <p><span className="curr-list-type">Official Event Page:</span> <a href={ event.externalLink } target="_blank">{ event.externalLink }</a></p>
           </div> 
           
             <div className="edit-post-form">
               { editArea }
             </div>
           
           <div className="author-info">
             <div>Posted by { event.authorFirstName }{ event.authorLastName }</div>
             <div> { moment(event.createAt.toDate()).calendar() } </div>
           </div>
        </div>
        
      </div>
    )
    } else {
    return (
      <div className="container center">
        <p>Loading event...</p>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.match.params.id;
  const events = state.firestore.data.events;
  const event = events ? events[id] : null

  return {
    id: id,
    event: event,
    auth: state.firebase.auth
  }
}

export default compose(
  connect(mapStateToProps),
  firestoreConnect([{
     collection: 'events' 
  }])
)(EventDetails)
