const initState = {
    accessKey: 'empty initial'
}

const privateModeReducer = (state = initState, action) => {  
  switch (action.type){  
  case 'REMOVE_PRIVACY': {
    return Object.assign(state, { accessKey : action.accessKey })
  }
  default:   
    return state 
  }
}

export default privateModeReducer