export default function ConvertTimefunction(t){
  let tx = t.split(":");
  let parsedtx = parseInt(tx[0]);
  if( parsedtx >= 12 ){
    if(parsedtx === 12){
      tx.push(" PM");
      tx.splice(1, 0, ":")
      let timePM = tx.join("");
      return timePM
    } else {
    let noonDiff = parseInt(tx[0]) - 12;
    tx.shift();
    tx.push(" PM");
    tx.unshift(noonDiff.toString() + ":");
    let timePM = tx.join("");
    return timePM
    }
  } else {
   tx.push(" AM");
   if (tx[0].toString() === "00"){
      tx.splice(0, 1, "12");
   } 
   tx.splice(1, 0, ":"); 
   let timeAM = tx.join(""); 
   return timeAM
  }
};

 