import React, { Component } from 'react'
import { connect } from 'react-redux'
import { resetPassword } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'

export class PasswordReset extends Component {
  state = {
    email: ''
  }

  handleChange = (e) =>{
    this.setState({
        [e.target.id]: e.target.value
    })   
  }
  handleSubmit = (e) =>{
    e.preventDefault();
    this.props.resetPassword(this.state.email);
  } 

  render() {
    const { auth, keyPass } = this.props;
    if (keyPass !== "go&s33") return <Redirect to="/" />
    if (auth.uid) return <Redirect to="/createevent" />

    return (
      <div className="form-container">
        <form onSubmit={this.handleSubmit}>
          <h1>Password Reset</h1>
          <p>Enter Your email address below and we'll send you a link to reset your password.</p>
          <div className="input-field">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" onChange={this.handleChange}/>
          </div>
          <div className="input-field auth-choice">
            <button>Send</button>

          </div>
        </form>
       
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return{
    authError: state.auth.authError,
    auth: state.firebase.auth,
    keyPass: state.privateMode.accessKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (x) => dispatch(resetPassword(x))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset)
