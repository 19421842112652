import authReducer from './authReducer'
import eventReducer from './eventReducer'
import emailSharesReducer from './emailSharesReducer';
import { combineReducers } from 'redux'
import { firestoreReducer } from 'redux-firestore'
import { firebaseReducer } from 'react-redux-firebase'
import favoriteReducer from './favoriteReducer';
import privateModeReducer from './privateModeReducer';


const rootReducer = combineReducers({
    auth: authReducer,
    event: eventReducer,
    emailshare: emailSharesReducer,
    favorite: favoriteReducer,
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    privateMode: privateModeReducer
});

export default rootReducer