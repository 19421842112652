import React, { Component } from 'react'
import { connect } from 'react-redux'
import { updateEvent } from '../../store/actions/eventActions'
import DeleteEvent from '../events/DeleteEvent'
import { withRouter } from 'react-router-dom'

export class EditEvent extends Component {
  state = {
    title: this.props.myeventcontent.title,
    description: this.props.myeventcontent.description,
    eventDate: this.props.myeventcontent.eventDate,
    timeStart: this.props.myeventcontent.timeStart,
    timeEnd: this.props.myeventcontent.timeEnd,
    locationName: this.props.myeventcontent.locationName,
    fullAddress: this.props.myeventcontent.fullAddress,
    externalLink: this.props.myeventcontent.externalLink,
    createAt: this.props.myeventcontent.createAt,
    ticketType: this.props.myeventcontent.ticketType,
    eventRefId: this.props.myeventid,
    eventAuthorized: true
  }

  handleChange = (e) =>{
    this.setState({
        [e.target.id]: e.target.value
    })   
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.updateEvent(this.state, this.props.myeventid);
    this.props.history.push('/updatesuccess');
  } 

  handlePostVerify = (e) => {
    e.preventDefault();
    this.props.updateEvent(this.state, this.props.myeventid);
  }
  
  render() {
    return (
      <div>
        <DeleteEvent deleteref={this.state.eventRefId}/>
      <div className="form-container">
        <form onSubmit={this.handleSubmit}>
          
          <h1>Edit This Event<i className="material-icons">edit</i></h1>   

          <div className="input-field">
            <label htmlFor="title">Event Title:</label>
            <input type="text" id="title" value={this.state.title} onChange={this.handleChange}/>
          </div>
          <div className="input-field">
            <label htmlFor="description">Event Description:</label>
            <textarea id="description" className="materialize-textarea" value={this.state.description} onChange={this.handleChange}/>
          </div>
          <div className="input-field">
            <label htmlFor="eventDate">Date:</label>
            <input type="date" id="eventDate" value={this.state.eventDate} onChange={this.handleChange} />
          </div>
          <div className="input-field">
            <label htmlFor="timeStart" className="active">Set New Start Time:</label>
            <span><i>**leave empty to keep the Start Time <b>"{ this.props.myeventcontent.formattedStart }"</b> unchanged</i></span>
            <input type="time" id="timeStart" value={this.state.startTime} pattern="[0-9]{2}:[0-9]{2} (am|pm|AM|PM)" className="active"  onChange={ this.handleChange } />
          </div>
          <div className="input-field">
            <label htmlFor="timeEnd" className="active">Set New End Time:</label>
            <span><i>**leave empty to keep the End Time <b>"{ this.props.myeventcontent.formattedEnd}"</b> unchanged</i></span>
            <input type='time' id="timeEnd" value={this.state.endTime} onChange={ this.handleChange } />
          </div>
          <div className="input-field">
            <label htmlFor="locationName">Organizing Venue Name:</label>
            <input type="text" id="locationName" value={this.state.locationName} onChange={this.handleChange}/>
          </div>
          <div className="input-field">
            <label htmlFor="fullAddress">Event Address: ( example: "Smith Auditorium, 555 Main St., St. Louis, MO 65432" )</label>
            <input type="text" id="fullAddress" value={this.state.fullAddress} onChange={this.handleChange}/>
          </div>
          <div className="input-field">
            <label htmlFor="externalLink">Link to Details:  ( example: "https://..." )</label>
            <input type="text" id="externalLink" value={this.state.externalLink} onChange={this.handleChange}/>
          </div>
          <div className="input-field">
            <label htmlFor="ticketType">Event Type: </label>
            <span className="label-note"><i>*This indicates if the event is free or requires ticketing or other registration. This is only informational, all users should visit the offical webpage for official ticketing information. </i></span>
            <select id="ticketType" onChange={this.handleChange} value={this.state.ticketType} defaultValue={'Free Event'} >
              <option value="Free Event">Free Event</option>
              <option value="Free, Registration or RSVP Required">Free, Registration or RSVP Required</option>
              <option value="Ticketed Event">Ticketed Event</option>
              <option value="Member Event">Member Event</option>
              <option value="Free with Admission">Free with Admission</option>
            </select>
          </div>

          <div className="input-field">
            <button id="create">Update</button>
          </div>
        </form>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateEvent: (event) => dispatch(updateEvent(event))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditEvent))
