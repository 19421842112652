const initState = {
    events: [
    ]
  }
  
  const eventReducer = (state = initState, action) => {
    switch (action.type) {
      case 'CREATE_EVENT':
        return state;

      case 'CREATE_EVENT_ERROR':
        return state;

      case 'UPDATE_EVENT':
        return state;

      case 'UPDATE_EVENT_ERROR':
        return state;    

      case 'DELETE_EVENT':
        return state;

      case 'DELETE_EVENT_ERROR':
        return state;
        
      default: 
        return state;    
    }
  }
  
  export default eventReducer