
import React, { Component } from 'react'
import { Redirect, withRouter  } from 'react-router-dom'
import { connect } from 'react-redux'
import { noPasswordSignUp } from '../../store/actions/authActions'
import  { emailDomainCheck } from '../../helpers/Validation'

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  orgName: '',
  orgWebsite: '', 
  emailError: '',
  passwordError: '',
  firstNameError: '',
  lastNameError: '',
  orgNameError: '',
  orgWebsiteError: ''
}

export class NoPassSignUp extends Component {
  state =  initialState; 

  handleChange = (e) => {
    this.setState({
        [e.target.id]: e.target.value
    })   
  }

  validateSignUp = () => {  
    let emailError = '';
    let passwordError = '';
    let firstNameError = '';
    let lastNameError = '';
    let orgNameError = '';
    let orgWebsiteError = '';
    let req = 'this field is required'
   
  //test for email errors
  let emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (!emailRegEx.test(this.state.email)){
    emailError = 'invalid email format';
  }

  //test for password errors
  let passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
  if (!passwordRegEx.test(this.state.password)){
    passwordError = 'passwords have at least 8 characters, include a number, a special character (! @ # $ % ^ or &), an uppercase, and a lowercase letter';
  }
  
  //test for firstName errors
  if (this.state.firstName.length > 20){
    firstNameError = 'first name must be less than 20 characters';
  }
  let personNameRegEx = /^[a-zA-Z][0-9a-zA-Z .,'-]*$/;
  if (!personNameRegEx.test(this.state.firstName)){
    firstNameError = 'first name must not include numbers or special characters; a hyphen is okay'
  }
  
  //test for lastName errors
  if (this.state.lastName.length > 20){
    lastNameError = 'last name must be less than 20 characters';
  }
  if (!personNameRegEx.test(this.state.lastName)){
    lastNameError = 'last name must not include numbers or special characters; a hyphen is okay'
  }

  //test for firstName errors
  if (this.state.orgName.length > 50){
    orgNameError = 'first name must be less than 50 characters';
  }

  //test for orgWebsite errors
  let orgWebsiteRegEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
  if (!orgWebsiteRegEx.test(this.state.orgWebsite)){
    orgWebsiteError = 'website address must start with http:// OR https://...';
  }

  //test to see if email and web domains match
  const domainCheck = emailDomainCheck(this.state.email, this.state.orgWebsite);
  if(domainCheck === false){
    orgWebsiteError = 'web address and email address domain names must match.';
    emailError = 'web address and email address domain names must match.';
  }

  //require all fields
  if (!this.state.email){
    emailError = req;
  }
  if (!this.state.password){
    passwordError = req;
  }
  if (!this.state.firstName){
    firstNameError = req;
  }
  if (!this.state.lastName){
    lastNameError = req;
  }
  if (!this.state.orgName){  
    orgNameError = req;
  }
  if (!this.state.orgWebsite){
    orgWebsiteError = req;
  }

  //set state based on error variables
  if (emailError || passwordError || firstNameError || lastNameError || orgNameError || orgWebsiteError ){
    this.setState({ emailError,  passwordError, firstNameError, lastNameError, orgNameError, orgWebsiteError });
    return false;
  }

  return true;
};

  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = this.validateSignUp();

    if(isValid){
      this.setState(initialState);
      this.props.noPasswordSignUp(this.state);
    }
  } 

  render() {
    const { auth, authError, keyPass, profile } = this.props;

    if (profile.isEmpty === false) return <Redirect to='/signupsuccess' />

    return (
      <div className="form-container">
        
        <form onSubmit={this.handleSubmit} noValidate>
          <h1>Register To Post New Events</h1>
          <p>Share you organization's information and valid professional email address to complete registration.</p>
          <div className="input-field">
            <label htmlFor="email">Your Organizational Email Address:</label>
            <input type="email" id="email" onChange={this.handleChange} />
            <div className="valid-error">{this.state.emailError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="password">Set Password:</label>
            <input type="password" id="password" onChange={this.handleChange}/>
            <div className="valid-error">{this.state.passwordError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="firstName">First Name:</label>
            <input type="text" id="firstName" onChange={this.handleChange}/>
            <div className="valid-error">{this.state.firstNameError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="lastName">Last Name:</label>
            <input type="text" id="lastName" onChange={this.handleChange}/>
            <div className="valid-error">{this.state.lastNameError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="orgName">Organization Name:</label>
            <input type="text" id="orgName" onChange={this.handleChange}/>
            <div className="valid-error">{this.state.orgNameError}</div>
          </div> 
          <div className="input-field">
            <label htmlFor="orgWebsite">Organization Website: ( example: "http://...")</label>
            <input type="text" id="orgWebsite" onChange={this.handleChange}/>
            <div className="valid-error">{this.state.orgWebsiteError}</div>
          </div>   

          <div className="input-field">
            <button>Register</button>
            <div className="red-text center">
              { authError ? <p>{ authError }</p> : null }
            </div>    
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    profile: state.firebase.profile,
    keyPass: state.privateMode.accessKey   
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    noPasswordSignUp: (newUser) => dispatch(noPasswordSignUp(newUser))
    // signOut: () => dispatch(signOut())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NoPassSignUp))
