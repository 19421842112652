import React from 'react';
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import { signOut } from '../../store/actions/authActions'

const SignedInLinks = (props) => {
    
    return(
        <ul>
            <li className="icon-toggle"><NavLink to='/'><i className="material-icons">calendar_today</i>Events</NavLink></li>
            <li className="icon-toggle"><NavLink to='/about'><i className="material-icons">info</i>About</NavLink></li>
            <li className="icon-toggle"><NavLink to='/seenstl-admin'><i className="material-icons">event</i>Post Event</NavLink></li>
            <li className="icon-toggle"><a onClick={ props.signOut }><i className="material-icons">person_outline</i>Log Out</a></li>
            <li className="icon-toggle"><NavLink to='/accountinfo'><i className="material-icons">account_circle</i>Your Profile</NavLink></li>
        </ul>      
    )
}
const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}
export default connect(null, mapDispatchToProps)(SignedInLinks)