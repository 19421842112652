import React from 'react';
import { Link } from 'react-router-dom'
import SignedInLinks from "./SignedInLinks"
import SignedOutLinks from "./SignedOutLinks"
import { connect } from 'react-redux'

const Navbar = (props) => {
    const { auth, profile, keyPass } = props;
    const hideHeader = { display: "none" };
    const links = (auth.uid) ? <SignedInLinks profile={profile} /> : <SignedOutLinks />;
    return(
        
        <header> 
          <div id="logo">
          <Link to="/">
            <img src="../SEEN_STL_LOGO_01blk.svg" alt="SEEN STL Logo" width="250px" height="auto"/>
          </Link>
          </div>
          <nav>
  
              { links }

          </nav>  
          
        </header>
    )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    keyPass: state.privateMode.accessKey
  }
}
export default connect(mapStateToProps)(Navbar)