import ConvertTime from '../../helpers/ConvertTime'


export const createEvent = (event) => {
    return(dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.profile;
        
        firestore.collection('events').add({
          ...event, 
          authorFirstName: profile.firstName,
          authorLastName: profile.lastName,
          authorOrgName: profile.orgName,
          authorOrgWebsite: profile.orgWebsite,
          authorId: authorId,
          formattedStart: ConvertTime(event.timeStart),
          formattedEnd: ConvertTime(event.timeEnd),
          createAt: new Date()
        }).then(() => {
          dispatch({ type: 'CREATE_EVENT', event });
        }).catch((err) => {
          dispatch({ type: 'CREATE_EVENT_ERROR', err  });
        }) 
    }
  }

  export const updateEvent = (event) => {
    return(dispatch, getState, { getFirebase, getFirestore }) => {
        //make async call to database
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.profile;
        firestore.collection('events').doc(event.eventRefId).set({
          ...event, 
          authorFirstName: profile.firstName,
          authorLastName: profile.lastName,
          authorOrgName: profile.orgName,
          authorOrgWebsite: profile.orgWebsite,
          authorId: authorId,
          formattedStart: ConvertTime(event.timeStart),
          formattedEnd: ConvertTime(event.timeEnd),
          createAt: new Date()
        }).then(() => {
          dispatch({ type: 'UPDATE_EVENT', event });
        }).catch((err) => {
          dispatch({ type: 'UPDATE_EVENT_ERROR', err  });
        }) 
    }
  }

  export const deleteEvent = (idtodelete) => {
   
 return(dispatch, getState, { getFirebase, getFirestore }) => {
     //make async call to database
     const firestore = getFirestore();
     const profile = getState().firebase.profile;
     const authorId = getState().firebase.profile;

     firestore.collection('events').doc(idtodelete).delete(
     ).then(() => {
       dispatch({ type: 'DELETE_EVENT', idtodelete });
     }).catch((err) => {
       dispatch({ type: 'DELETE_EVENT_ERROR', err  });
     }) 
 }
}
  

