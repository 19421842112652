import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'


const About = () => {
    
    return(
        <div className="info-container">
            <h1>ABOUT SEEN | STL</h1>
            <ul>
              <li><p><strong>How to use SEEN | STL</strong> <br /> Browse event listings &rarr; create a personalized list &rarr; email to yourself or a friend &rarr; repeat. </p></li>
              <li><p><strong>Posting events on SEEN | STL </strong><br /> Posting is open to all St. Louis metro area arts organizations. Register with a verified email from your arts organization to start posting.</p></li>
              <li><p><strong>Support SEEN | STL </strong><br /> We're currently looking at resources to sustain ongoing administration, maintenance, and development of SEEN | STL. If you find this tool useful and would like to support its ongoing mission please contact: <a href="mailto: info@seenstl.com">info@seenstl.com</a></p></li>
            </ul>  
           
            <div className="backlink">
              <Link to="/">Back to listings</Link>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      // keyPass: state.privateMode.accessKey   
    }
  }
  export default connect(mapStateToProps)(About)