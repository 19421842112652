import React, { Component } from 'react'
import { connect } from 'react-redux'
import { unprotect } from '../../store/actions/privateModeActions'
import { Redirect } from 'react-router-dom'

export class PrivateMode extends Component {
  state = {
    accessKey: 'placeholder_738740q374bbQQ'  //make an empty string to password protect all routes
  }
  handleChange = (e) =>{
    this.setState({
        [e.target.id]: e.target.value
    })   
  }
  handleSubmit = (e) =>{
    e.preventDefault();
    this.props.unprotect(this.state.accessKey)
  } 

  render() {
    const gatekeeper = 'visart123'
    if (this.props.keyPass === gatekeeper) return <Redirect to="/" />

    return (
      <div id="private-mode-wrapper">  
        <img src="../SEEN_STL_LOGO_01blk.svg" alt="SEEN STL Logo" />
      <div className="form-container">
        <form onSubmit={this.handleSubmit}>
          <h1>PREVIEW</h1>
          <div className="input-field preview-input">
            <label htmlFor="password">Access Key</label>
            <input type="password" id="accessKey" onChange={this.handleChange}/>
          </div>
          <span id="key-error">{ (this.props.keyPass === 'placeholder' || this.props.keyPass === "empty initial" )? '' : 'please enter a valid access key' }</span>
          <div className="input-field auth-choice">
            <button>SUBMIT</button>

            <div className="red-text center">
              
            </div>

          </div>
        </form>
       
      </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return{
    keyPass: state.privateMode.accessKey
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    unprotect: (keyPass) => dispatch(unprotect(keyPass))
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrivateMode)
