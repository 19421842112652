
export const noPasswordSignUp = (newUser) => {
  return (dispatch, getState, {getFirebase, getFirestore}) => {
      const firebase = getFirebase();
      const firestore = getFirestore();        
      const actionCodeSettings = {
          url: 'https://seenstl.com/signin',
          // url: 'http://localhost:3000/signin',
          handleCodeInApp: true
        };
        
        firebase.auth().createUserWithEmailAndPassword(
          newUser.email,
          newUser.password          
        ).then((resp) => {
          return firestore.collection('users').doc(resp.user.uid).set({
              firstName: newUser.firstName,
              lastName: newUser.lastName,
              initials: newUser.firstName[0] + newUser.lastName[0],
              orgName: newUser.orgName,
              emailAddress: newUser.email,
              orgWebsite: newUser.orgWebsite,
              bornOn: new Date()
          })
        }).then(
        firebase.auth().sendSignInLinkToEmail(
          newUser.email,
          actionCodeSettings
          )
        ).then(function() {
            window.localStorage.setItem('emailForSignIn', newUser.email);
          }).then(() => {
            
            dispatch({ type: 'NOPASSSIGNUP_SUCCESS' })
          }).catch (err => {
            
            dispatch({ type: 'NOPASSSIGNUP_ERROR', err})
          })  
  }
}


export const noPassSignIn = (credentials) => {
    return(dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();

      firebase.auth().fetchSignInMethodsForEmail(
        credentials.email
      ).then((resp) => {

        if((resp[0] === "emailLink") || (resp[0] === "password") ){
          
          const actionCodeSettings = {
            url: 'https://seenstl.com/signin',
            // url: 'http://localhost:3000/signin',
            handleCodeInApp: true
          };

          firebase.auth().sendSignInLinkToEmail(
              credentials.email,
              actionCodeSettings
          ).then(function() {
            
            window.localStorage.setItem('emailForSignIn', credentials.email);
            
          }).then(() => {
              
          }).catch((err) => {
              
          });
        } else {
          
        }
    
      }).catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
    });  

    }
}

export const doEmailCheck = () => {
     
    return(dispatch, getState, {getFirebase, getFirestore}) => {
      const firebase = getFirebase();
     
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
   
      var emailz = window.localStorage.getItem('emailForSignIn'); //needed to be "var" not "const"
      if (!emailz) {
         emailz = window.prompt('Please provide your email for confirmation');
      }
      firebase.auth().signInWithEmailLink(emailz, window.location.href)
        .then(function(result) {
          
          window.localStorage.removeItem('emailForSignIn');
        }).catch(function(error) {
          
        });
    }

   }
   
  }

  export const doMethodCheck = (usereaddress) => {
      return(dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        
        firebase.auth().fetchSignInMethodsForEmail(
          usereaddress
        ).then((resp) => {
          
            if( resp[0] === "password" ){
                
              dispatch({ type: 'METHODCHECK_SUCCESS' })
            } else {
              dispatch({ type: 'METHODCHECK_ERROR'})
            }
            }).catch (err => {
              
              dispatch({ type: 'METHODCHECK_ERROR', err})
            })  
          } 
      }
    
    
   
  
export const signOut = () => {
  if(window.confirm("Are you sure you want to log out?")){
  return (dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();
      alert("Logging Out.");
      firebase.auth().signOut().then(() => {
        dispatch({ type: 'SIGNOUT_SUCCESS'});
      });
  }
 }
}

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        const firebase = getFirebase();
        const firestore = getFirestore();
        
        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then((resp) => {
            return firestore.collection('users').doc(resp.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                initials: newUser.firstName[0] + newUser.lastName[0],
                orgName: newUser.orgName,
                emailAddress: newUser.email,
                orgWebsite: newUser.orgWebsite
            })
        })
        .then(() => {

            var user = firebase.auth().currentUser;

            user.sendEmailVerification().then(function() {
              // Email sent.
              
            }).catch(function(error) {
              // An error happened.
              
            });

        })
        .then(() => {
            dispatch({ type: 'SIGNUP-SUCCESS'})
        }).catch (err => {
            dispatch({ type: 'SIGNUP_ERROR', err})
        })
    }
}

export const sendVerify = () => {
    return (dispatch, getState, {getFirebase}) => {
      const firebase = getFirebase();
      var user = firebase.auth().currentUser;

      user.sendEmailVerification().then(function() {
        // Email sent.
      }).catch(function(error) {
      // An error happened.
    });
  }
}         

export const resetPassword = (resetEmail) => {
    return(dispatch, getState, {getFirebase}) => {
        const firebase = getFirebase();
        const auth = firebase.auth();
        const emailAddress = resetEmail;
      
        auth.sendPasswordResetEmail(emailAddress).then(function() {
        
            alert('A password reset link was sent! Find the reset link in your email inbox.');
          }).catch(function(error) {
           
            alert('A Password Reset Link Was NOT Sent. Are you sure you are registered?');
          });
    }
}

