import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'

const EmailReminder = (props) => {
    const { auth } = props;

    return(
        <div className="info-container">
            <h1>Thanks! We just sent you a message. </h1>
            <p>Visit your email inbox for instructions to complete your account verification.</p>
            <p class="reload-instruction">Once verified you may need to press your browser's <i class="material-icons refresh-icon">refresh</i> reload button for your new status to take effect.</p>
            <div className="backlink">
               <Link to="/">Back to listings</Link>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth
  }
}

export default connect(mapStateToProps)(EmailReminder)
