import React, { Component } from 'react'
import EventList from '../events/EventList'
import EmailShares from '../emailshares/EmailShares'
import { Redirect } from 'react-router-dom'
import { addFavorite, removeFavorite, emptyFavorites } from '../../store/actions/favoriteActions'
import { sendEmailShare } from '../../store/actions/emailShareActions'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'

const initialState = {
  senderEmail: '',
  emailOne: '',
  emailTwo: '',
  emailThree: '',
  emailedFavs: [] 
}

class Dashboard extends Component{
  
  state = initialState;
  
  
  addEmailedFavs = (idFromAdd) => {
    const { events, favorites, addFavorite } = this.props; 
    addFavorite(idFromAdd) //this adds a new favorite to the redux store
    const favsInit = favorites.favorites
    //this will concatenate the new ID so it's available in the filterHelp array since the favorites store won't yet be updated
    const favs = favsInit.concat(idFromAdd)
    const filterHelp1 = (x) => {
      if(events && favorites){
        const result = x.filter(function(x_el) {
          return favs.filter(function(favs_el) {
          return favs_el === x_el.id;
        }).length !== 0
      })
        return result
      }
    }
   const myPicks = filterHelp1(events);
   const picksForState = myPicks && myPicks.map((ev) => {
    return  ev 
   });
    this.setState({ emailedFavs: picksForState});
  }


  removeEmailedFavs = (idFromRemove) => {
    const { events, favorites, removeFavorite } = this.props; 
    removeFavorite(idFromRemove) //this adds a new favorite to the redux store
    const favsInit = favorites.favorites
    const favsX = favsInit.filter(function(favsItem) {
      return favsItem !== idFromRemove;
    });

    const filterHelp1 = (x) => {
      if(events && favorites){
        const result = x.filter(function(x_el) {
          return favsX.filter(function(favs_el) {
          return favs_el === x_el.id;
        }).length !== 0
      })
        return result
      }
    }
   const myPicks = filterHelp1(events);
   const picksForState = myPicks && myPicks.map((ev) => {
    return  ev 
   });
    this.setState({ emailedFavs: picksForState});
  }

  handleChange = (e) => {
    this.setState({
        [e.target.id]: e.target.value,
    })   
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.sendEmailShare(this.state);
    alert("Success! Your selections have been sent.")
    setTimeout(() => {
      this.setState(initialState); 
    }, 250);
    setTimeout(() => {
      this.props.emptyFavorites();
    }, 250);
    
    //this needs to reload through this redirect
    // this.props.history.push('/sharesuccess');
  } 
    
    render(){
        const { events, emailshares, auth, profile, keyPass, favorites } = this.props;
        if (!auth.uid){
          const currentPass = 'visart123'
          if ((keyPass !== currentPass)) return <Redirect to="/protected-page" />
        }
       
        return (
            <div>
              <main>
                <section id="ev-section">
                  <h2>UPCOMING EVENTS</h2>
                  <EventList events={ events } favorites={ favorites } addEmailedFavs={this.addEmailedFavs} auth={ auth } profile={ profile } handleChange={this.handleChange} handleSubmit={this.handleSubmit}  />  
                </section>    
              </main>    

                <EmailShares emailshares={ emailshares } dashState={this.state} emailedFavs={ this.state.emailedFavs } events={ events } removeEmailedFavs={this.removeEmailedFavs} handleChange={this.handleChange} handleSubmit={this.handleSubmit} />
                  
            </div>
           
        )
    }
}

const mapStateToProps = (state) => {
  return {
    events: state.firestore.ordered.events,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    favorites: state.favorite,
    keyPass: state.privateMode.accessKey
   
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    sendEmailShare: (emailshare) => dispatch(sendEmailShare(emailshare)),
    addFavorite: (favo) => dispatch(addFavorite(favo)),
    removeFavorite: (favo) => dispatch(removeFavorite(favo)),
    emptyFavorites: () => dispatch(emptyFavorites())
  }
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  firestoreConnect([
    { collection: 'events', orderBy: ['eventDate', 'asc']  },
    { collection: 'emailshares', orderBy: ['createdAt', 'desc']  }
  ])
)(Dashboard)

