import React, { Component } from 'react'
import { connect } from 'react-redux'
import { sendEmailShare } from '../../store/actions/emailShareActions'
import FavoriteList from './FavoriteList'

export class EmailShares extends Component {
  state = {
    favsOpen: false,
    formOpen: true
  }
  toggleFavs = (e) =>{
    e.preventDefault();
    this.setState({ favsOpen: !this.state.favsOpen });
  }
  toggleForm = (e) =>{
    e.preventDefault();
    this.setState({ formOpen: !this.state.formOpen });
  }
  render() {
    const uniqueFavorites = [...new Set(this.props.favorites.favorites)]; 
    return(
      <div id="fav-area" className={this.state.favsOpen === true ? "shadow fav-hide" : "shadow"}>
          <div id="fav-count">
             <div id="counter">{(this.props.emailedFavs.length != 0)? uniqueFavorites.length : "0"}</div>
             <div id="fav-title"> Event{(this.props.emailedFavs.length === 1 )? '' : 's'} Added To Your List</div>
          </div>
         <FavoriteList  favorites={ this.props.favorites } emailedFavs={ this.props.emailedFavs } events={ this.props.events } removeEmailedFavs={this.props.removeEmailedFavs} favsOpen={ this.state.favsOpen  } />

         <div id="fav-util">
           <div id="fav-toggle" onClick={this.toggleFavs}><i className="material-icons">{this.state.favsOpen === true ? "arrow_right" : "arrow_left"}</i></div>
           <div id="fav-sharea"> Email your event{(this.props.emailedFavs.length === 1 )? '' : 's'}:</div>
           <div id="fav-email" onClick={this.toggleForm}>
             <i className="material-icons">&#xe0be;</i>      
           </div>
         </div> 

         <div id="fav-form" className={this.state.formOpen === true ? "hide-show" : ""}>
           <div id="close-form" onClick={this.toggleForm}>close &#10005;</div>
             <h3>Send Your Events:</h3>
             <form onSubmit={this.props.handleSubmit} className="white">
                 <div className="input-field">
                   <label htmlFor="senderEmail">Your Email (required):</label>
                   <input type="text" id="senderEmail" value={this.props.dashState.senderEmail} onChange={this.props.handleChange}/>
                 </div>
                 <div className="input-field">
                   <label htmlFor="emailOne">Recipient Email 1 (required):</label>
                   <input type="text" id="emailOne" value={this.props.dashState.emailOne} onChange={this.props.handleChange}/>
                 </div>
                 <div className="input-field">
                   <label htmlFor="emailTwo">Recipient Email 2:</label>
                   <input type="text" id="emailTwo" value={this.props.dashState.emailTwo} onChange={this.props.handleChange}/>
                 </div>
                 <div className="input-field">
                   <label htmlFor="emailThree">Recipient Email 3:</label>
                   <input type="text" id="emailThree" value={this.props.dashState.emailThree} onChange={this.props.handleChange}/>
                 </div>
                 <div className="input-field">
                   <button className="btn pink lighten-1 z-depth-0">send</button>
                 </div>
               </form>
             </div>
           </div>
          
  )
  }
}

const mapStateToProps = ( state ) => {
  return{
      favorites: state.favorite,
      //events: state.firestore.ordered.events
  }
} 
const mapDispatchToProps = (dispatch) => {
  return {
    sendEmailShare: (emailshare) => dispatch(sendEmailShare(emailshare))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EmailShares)

    
