export const addFavorite = (favorite) => {
    return{ type: 'ADD_FAVORITE', favorites: favorite };
  }

export const removeFavorite = (favorite) => {
    return{ type: 'REMOVE_FAVORITE', favorites: favorite };
  }  

export const emptyFavorites = () => {
    return{ type: 'EMPTY_FAVORITE', favorites: [] };
  }  

   
