import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { connect } from 'react-redux'


const CreateSuccess = ({keyPass}) => {
  if (keyPass !== "go&s33") return <Redirect to="/" />
    return(
        <div className="info-container">
            <p><span className="success">SUCCESS!</span> You've created your event. Return to event listings to see your event. You can also edit your organizations event information from your profile page.</p>
            <Link to="/">Back to listings</Link>
        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    keyPass: state.privateMode.accessKey   
  }
}
export default connect(mapStateToProps)(CreateSuccess)