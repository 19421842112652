import React from 'react';
import { Redirect } from 'react-router-dom'
import SignIn from './SignIn'
import CreateEvent from '../events/CreateEvent'
import { connect } from 'react-redux'

const AuthManagement = (props) => {
    const { auth, keyPass } = props;
    const authlinks = (auth.uid) ? <CreateEvent /> : <SignIn />;
    return(
        <div className="auth-page-wrapper">

            { authlinks }

        </div>
    )
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    keyPass: state.privateMode.accessKey   
  }
}
export default connect(mapStateToProps)(AuthManagement)