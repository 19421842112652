import React from 'react';
import { NavLink } from 'react-router-dom'

const SignedOutLinks = () => {
    return(
        
        <ul>
            <li className="icon-toggle"><NavLink to='/'><i className="material-icons">calendar_today</i>Events</NavLink></li>
            <li className="icon-toggle"><NavLink to='/about'><i className="material-icons">info</i>About</NavLink></li>
            <li className="icon-toggle"><NavLink to='/support'><i className="material-icons">attach_money</i>Support</NavLink></li>
            <li className="icon-toggle"><NavLink to='/seenstl-admin'><i className="material-icons">event</i>Post Event</NavLink></li>
        </ul>      

    )
}

export default SignedOutLinks