export const emailDomainCheck = (email, fulldomain) => {
  const parts = email.split('@');
  if (parts.length === 2) {
      if (fulldomain.includes(parts[1])) {
          return true;
      }
  }
  return false;
}


  

        