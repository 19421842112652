import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createEvent } from '../../store/actions/eventActions'
import { signOut, doMethodCheck } from '../../store/actions/authActions'
import { Redirect, withRouter } from 'react-router-dom'

const initialState = {
  title: '',
  description: '',
  eventDate: '',
  timeStart: '',
  timeEnd: '',
  locationName: '',
  fullAddress: '',
  externalLink: '',
  titleError: '',
  descriptionError: '',
  eventDateError: '',
  timeStartError: '',
  timeEndError: '',
  locationNameError: '',
  fullAddressError: '',
  externalLinkError: '',
  ticketType: 'Free Event',
  ticketTypeError: ''
}

export class CreateEvent extends Component {
  state = initialState;

  handleChange = (e) => {
    this.setState({
        [e.target.id]: e.target.value
    })   
  }

  validateCreate = () => {  
    let titleError = ''
    let descriptionError = ''
    let eventDateError = ''
    let timeStartError = ''
    let timeEndError = ''
    let locationNameError = ''
    let fullAddressError = ''
    let externalLinkError = ''
    let ticketTypeError = ''
    let req = 'this field is required'
   
   //test for orgWebsite errors
   let externalLinkRegEx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/
   if (!externalLinkRegEx.test(this.state.externalLink)){
    externalLinkError = 'website address must start with http:// OR https://...';
   }

   //limit field length
  if (this.state.title.length > 90){
    titleError = 'event title should be less than 90 characters';
  }
  if (this.state.description.length > 800){
    descriptionError = 'event description should be less than 800 characters';
  }
  if (this.state.locationName.length > 50){
    locationNameError = 'location name should be less than 50 characters';
  }
  if (this.state.fullAddress.length > 100){
    fullAddressError = 'address should be less than 100 characters';
  }
  if (this.state.externalLink.length > 150){
    externalLinkError = 'external link should be less than 150 characters';
  }

  //require all fields
  if (!this.state.title){
    titleError = req;
  }
  if (!this.state.description){
    descriptionError = req;
  }
  if (!this.state.eventDate){
    eventDateError = req;
  }
  if (!this.state.timeStart){
    timeStartError = req;
  }
  if (!this.state.timeEnd){  
    timeEndError = req;
  }
  if (!this.state.locationName){
    locationNameError = req;
  }
  if (!this.state.fullAddress){
    fullAddressError = req;
  }
  if (!this.state.externalLink){
    externalLinkError = req;
  }
  if (!this.state.ticketType){
    ticketTypeError = req;
  }
 
  //set state based on error variables
  if (titleError || descriptionError || eventDateError || timeStartError || timeEndError || locationNameError || fullAddressError || externalLinkError || ticketTypeError ){
    this.setState({ titleError, descriptionError, eventDateError, timeStartError, timeEndError, locationNameError, fullAddressError, externalLinkError, ticketTypeError });
    return false;
  }

  return true;
};

  handleSubmit = (e) => {
    e.preventDefault();
    const isValid = this.validateCreate();
    
    if (isValid){
      this.setState(initialState);
      this.props.createEvent(this.state);
      alert("This event is created. Returning to event listings...")
      this.props.history.push('/createsuccess');
    }

  } 
  
  render() {
    const { auth, profile} = this.props;
    
    let twoWeeks = 1209600000;
    let lastSignIn = auth.lastLoginAt
    let timeNow = new Date();
    let milliNow = timeNow.getTime();
    let timeDiff = milliNow - lastSignIn;
    if(timeDiff > twoWeeks){
      this.props.signOut();
    }

    if (!auth.uid) return <Redirect to='/about' />
    
    return (
      <div className="form-container">

        <form onSubmit={this.handleSubmit} noValidate>
          <h1>Create a new event listing for { profile.orgName }</h1> 
          <p><b>Contributor Status:</b> { auth.emailVerified === true ? "Verified! You're authorized to post events." : "You must verify your email address to continue." }</p>  
          <div className="input-field">
            <label htmlFor="organization">Posting Organization:</label>
            <input type="text" id="organization" value={ profile.orgName }/>
          </div>
          <div className="input-field">
            <label htmlFor="title">Event Title:</label>
            <input type="text" id="title" onChange={this.handleChange} required/>
            <div className="valid-error">{this.state.titleError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="description">Event Description:</label>
            <textarea id="description" className="materialize-textarea" onChange={this.handleChange} required/>
            <div className="valid-error">{this.state.descriptionError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="eventDate">Date:</label>
            <input type="date" id="eventDate" onChange={this.handleChange} required/>
            <div className="valid-error">{this.state.eventDateError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="timeStart" className="active">Start Time:</label>
            <input type='time' id="timeStart" pattern="[0-9]{2}:[0-9]{2} (am|pm|AM|PM)" className="active"  onChange={ this.handleChange } required/>
            <div className="valid-error">{this.state.timeStartError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="timeEnd" className="active">End Time:</label>
            <input type='time' id="timeEnd" onChange={ this.handleChange } required/>
            <div className="valid-error">{this.state.timeEndError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="locationName">Organizing Venue Name:</label>
            <input type="text" id="locationName" onChange={this.handleChange} required/>
            <div className="valid-error">{this.state.locationNameError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="fullAddress">Event Address: ( example: "Smith Auditorium, 555 Main St., St. Louis, MO 65432" )</label>
            <input type="text" id="fullAddress" onChange={this.handleChange} required/>
            <div className="valid-error">{this.state.fullAddressError}</div>
          </div>
          <div className="input-field">
            <label htmlFor="externalLink">Link to Details: ( example: "https://..." )</label>
            <input type="text" id="externalLink" onChange={this.handleChange} required/>
            <div className="valid-error">{this.state.externalLinkError}</div>
          </div>

          <div className="input-field">
            <label htmlFor="ticketType">Event Type: </label>
          
            <select id="ticketType" onChange={this.handleChange} defaultValue={'Free Event'} required>
              <option value="Free Event">Free Event</option>
              <option value="Free, Registration or RSVP Required">Free, Registration or RSVP Required</option>
              <option value="Ticketed Event">Ticketed Event</option>
              <option value="Free Event">Member Event</option>
              <option value="Free with Admission">Free with Admission</option>
            </select>
          </div>


          <div className="input-field">
            <button id="create">Create</button>
          </div>
        </form>
      </div>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createEvent: (event) => dispatch(createEvent(event)),
    doMethodCheck: (usereaddress) => dispatch(doMethodCheck(usereaddress)),
    signOut: () => dispatch(signOut())
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateEvent))
