const initState = {
    emailshares: [
    ]
  }
  
  const emailSharesReducer = (state = initState, action) => {
    switch (action.type) {
      case 'SEND_FAVORITE':
        return state;
      case 'SEND_FAVORITE_ERROR':
        return state;
      default: 
        return state;    
    }
  }
  
  export default emailSharesReducer