import React, { Component } from 'react'
import { connect } from 'react-redux'
import { noPassSignIn, doEmailCheck, signOut } from '../../store/actions/authActions'
import { Redirect, withRouter  } from 'react-router-dom'
import { Link } from 'react-router-dom'



export class SignIn extends Component {
  state = {
    email: ''
  }

  handleChange = (e) =>{
    this.setState({
        [e.target.id]: e.target.value
    })   
  }
  handleSubmit = (e) =>{
    e.preventDefault();
    this.props.noPassSignIn(this.state);
    this.props.history.push('/signinsuccess');
  } 

  render() {
    const { authError, auth } = this.props;
    
    if(!auth.uid){
      this.props.doEmailCheck();
    }

    if(auth.uid) return <Redirect to="/createevent" />

    return (
      <div className="form-container">
  
        <form onSubmit={this.handleSubmit}>
          <div id="signin-reg"><h1>Sign In</h1> | <span><Link to="/signup">Register</Link></span></div>
          <p>Once registered, sign in here. You'll receive a link in your email inbox to log back in to make posts. No password is required.</p>
          
          <div className="input-field">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" onChange={this.handleChange}/>
          </div>

          <div className="input-field auth-choice">
            <button>Send Link</button>
            <div className="red-text center">
              { authError ? <span id="sign-in-error">{ authError }, please register or reset your password below.</span> : null }
            </div>
            
            <span className="not-registered">Don't have an account? <Link to="/signup">Register Here</Link> first.</span>
         
          </div>
        </form>
       
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return{
    authError: state.auth.authError,
    auth: state.firebase.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    noPassSignIn: (creds) => dispatch(noPassSignIn(creds)),
    doEmailCheck: () => dispatch(doEmailCheck()),
    signOut: () => dispatch(signOut())

  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SignIn))
