import React,{ Component } from 'react'
import Remove from '../favorites/Remove'
import moment from 'moment'
import SmallDate from '../../helpers/SmallDate'
import { connect } from 'react-redux'

class FavoriteList extends Component {
   render(){
    const myPicks = this.props.emailedFavs
    
    return (
           <div id="fav-item-wrapper" className={ this.props.favsOpen === true ? "show-items" : "hide-items" }>
           {myPicks && myPicks.map((ev, index) => {
               return(
                <div id="fav-item" key={index}>
                  <div className="fav-date"> {moment(SmallDate(ev.eventDate)).format("MM")}.{moment(SmallDate(ev.eventDate)).format("DD")} </div> 
                  <div className="fav-summary">
                    <ul>
                      <li>{ev.title} | <i>{ev.locationName}</i></li> 
                    </ul>
                  </div>
                  <div className="fav-ellipsis">&#8230;</div>
                    <Remove idToRemove= {ev.id} removeEmailedFavs={this.props.removeEmailedFavs}/>
                 
                </div> 
              )
           })}
           </div>
    )
}
}
const mapStateToProps = (state) => {
    return{
        favorites: state.favorite,
        events: state.firestore.ordered.events
    }
} 

export default connect(mapStateToProps)(FavoriteList)
