import React, { Component } from 'react'
import moment from 'moment'
import ConvertTime from '../../helpers/ConvertTime' 
import { Link } from 'react-router-dom'


class EventSummary extends Component {
    state = {
        showDesc: false
    }
    
    toggleDescription = () => {
        this.setState({ showDesc: !this.state.showDesc});
    }

    render(){
        const { auth, profile, event } = this.props;
   
    return(
          <ul>
            <li><h3>{event.title}</h3><Link to={ ((auth.uid && auth.emailVerified === true) && ( profile.orgName === event.authorId.orgName )) ? "/event/" + event.id : "/" } ><h3>&nbsp;<span className="edit-link-text">{ ((auth.uid && auth.emailVerified === true) && ( profile.orgName === event.authorId.orgName )) ? (<span>&#9998;edit</span>) : "" }</span></h3> </Link></li>
            <li className="event-time-loc"><span className="event-time">{ ConvertTime(event.timeStart)  } - { ConvertTime(event.timeEnd) }</span>  |  <span className="event-location"><i>{event.locationName}</i></span></li>
            <li className="ticket-type">{ event.ticketType }</li>
            <li className="event-address">{ event.fullAddress }</li>
            <li className="event-desc-toggle"> <span onClick={ this.toggleDescription }>details <i className="material-icons">{ this.state.showDesc === false ? "arrow_right" : "arrow_drop_down" } </i></span></li>
            <li className={ this.state.showDesc === false ? "event-description" : "event-desc-toggle" }>{ event.description }</li>
            <li className="more-info"><span className="event-website"><a href={ event.externalLink } target="_blank" rel="noopener noreferrer"> { event.externalLink ? "View Website" : "" }</a></span></li>
            <li className="fav-author">Posted&nbsp;<a href={ event.authorId.orgWebsite } target="_blank" rel="noopener noreferrer"> { "by " + event.authorId.orgName } </a>&nbsp; { moment(event.createAt.toDate()).calendar() } </li>
          </ul>
          
       
    )
    }
}

export default EventSummary
