import React, { Component } from 'react'
import { connect } from 'react-redux'
import { deleteEvent } from '../../store/actions/eventActions'
import { Redirect, withRouter } from 'react-router-dom'

export class DeleteEvent extends Component {

  handleClick = (e) => {
    e.preventDefault();
    const confirmDelete = prompt("Are you sure you want to delete this event? This cannot be undone. If so, type 'yes' and click 'OK'. If not, click CANCEL.")
    if(confirmDelete === "yes"){
        const IdToDelete = this.props.deleteref;
        this.props.deleteEvent(IdToDelete);
        alert("This event has been deleted.");
        this.props.history.push('/accountinfo');
    }  
    }
  
  render(){
 
    return (
      <div className="delete-event">
        <a onClick={this.handleClick}>delete this event</a>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    deleteEvent: (event) => dispatch(deleteEvent(event))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteEvent))
