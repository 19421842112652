import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

export class VerifyEvent extends Component {

  render() {
    const { auth, authError, keyPass } = this.props;
    // if (keyPass !== "go&s33") return <Redirect to="/" />  

    return (
      <div className="form-container">
        <p className="verif-instructions">Review the content above then click the link below to activate this post in the Events List.</p>
        <form onSubmit={this.props.handlePostVerify}>
          <div className="input-field">
            <button type="submit">Verify Post</button> 
            <div className="red-text center">
              { authError ? <p>{ authError }</p> : null }
            </div>    
          </div>
        </form>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    keyPass: state.privateMode.accessKey  
    
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
      // sendVerify: () => dispatch(sendVerify())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyEvent)
