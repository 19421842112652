export const sendEmailShare = (emailshare) => {
    return(dispatch, getState, {getFirebase, getFirestore}) => {
        //make async call to database
        const firestore = getFirestore();
        firestore.collection('emailshares').add({
          ...emailshare, 
          createdAt: new Date()
        }).then(() => {
          dispatch({ type: 'SEND_FAVORITE', emailshare });
        }).catch((err) => {
          dispatch({ type: 'SEND_FAVORITE_ERROR', err  });
        }) 
    }
  }

