import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import ConvertTime from '../../helpers/ConvertTime' 

const Account = (props) => {

        const { auth, profile, events, keyPass } = props;
        if (!auth.uid || auth.emailVerified === false) return <Redirect to='/' />
       
        const filterUserListings = () => {
            //I've included this here for testing but perhaps it should be moved?
            if(events){
              const currentDate = new Date().toISOString().slice(0,10);
             
              let filt = events.filter(
                (yoyo) => {
                  return ((yoyo.authorFirstName === profile.firstName) && (yoyo.authorId.orgName === profile.orgName) )
                } 
             );
             return filt
            }
          }
          const filteredEvents = filterUserListings();

    return(
        <div className="info-container">
            <h1>ACCOUNT INFO</h1>
            <h2>User Profile</h2>
            <ul>
                <li>First Name: { profile.firstName ? profile.firstName : "not available" }</li>
                <li>Last Name: { profile.lastName ? profile.lastName : "not available" }</li>
                <li>Email Address: { profile.emailAddress ? profile.emailAddress : "not available" }</li>
                <li>Organization: { profile.orgName ? profile.orgName : "not available" }</li>
                <li>Organization Website: { profile.orgWebsite ? profile.orgWebsite : "not available" }</li>
            </ul>

            <div id="ev-listings" className="post-history-wrapper">
              <h2>Event Post History</h2>
              <span>Click on event title to edit your event post information.</span>
              <hr></hr>
              { filteredEvents && filteredEvents.map(event => {
                
                return (
                  <div className="single-listing" key={event.id}>
                    
                    <ul>
                      <li><Link to={'/event/' + event.id} ><h3>{event.title} <i className="material-icons">edit</i></h3></Link></li>
                      <li>Date:&nbsp;{ event.eventDate }</li> 
                      <li className="event-address">Organization:&nbsp;{ event.authorId.orgName}</li>
                      <li className="event-time-loc"><span className="event-time">{ ConvertTime(event.timeStart)  } - { ConvertTime(event.timeEnd) }</span>  |  <span className="event-location"><i>{ event.locationName }</i></span></li>
                      <li className="event-description">{ event.description }</li>
                      <li className="event-address">{ event.fullAddress }</li>
                      <li>{ event.externalLink }</li>
                    </ul>

                    <hr></hr>

                  </div>
                )
              })}  
            </div>

            <div className="backlink">
              <Link to="/">Back to listings</Link>
            </div>

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
      auth: state.firebase.auth,
      profile: state.firebase.profile,
      events: state.firestore.ordered.events,
      keyPass: state.privateMode.accessKey   
    }
  }

  export default compose(
      connect(mapStateToProps),
  firestoreConnect([
    { collection: 'events', orderBy: ['eventDate', 'asc']  } 
  ])
  )(Account)