import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Navbar from './components/layout/Navbar'
import Footer from './components/layout/Footer'
import Dashboard from './components/dashboard/Dashboard'
import EventDetails from './components/events/EventDetails' 
import AuthManagement from './components/auth/AuthManagement'
import NoPassSignUp from './components/auth/NoPassSignUp'
import SignIn from './components/auth/SignIn'
import Verify from './components/auth/Verify'
import VerifyEvent from './components/auth/VerifyEvent'
import PasswordReset from './components/auth/PasswordReset'
import PrivateMode from './components/auth/PrivateMode'
import TypeChange from './components/auth/TypeChange'
import CreateEvent from './components/events/CreateEvent'
import SignUpSuccess from './components/info/SignUpSuccess'
import SignInSuccess from './components/info/SignInSuccess'
import ShareSuccess from './components/info/ShareSuccess'
import CreateSuccess from './components/info/CreateSuccess'
import UpdateSuccess from './components/info/UpdateSuccess'
import EmailReminder from './components/info/EmailReminder'
import Privacy from './components/info/Privacy'
import Support from './components/info/Support'
import About from './components/info/About'
import ComingSoon from './components/info/ComingSoon'
import Account from './components/info/Account'

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
            <Navbar />
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route path="/event/:id" component={EventDetails} /> 
            <Route path="/signup" component={NoPassSignUp} />
            <Route path="/signin" component={SignIn} />
            <Route path="/createevent" component={CreateEvent} />
            <Route path="/sharesuccess" component={ShareSuccess} />
            <Route path="/support" component={Support} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/comingsoon" component={ComingSoon} />
            <Route path="/accountinfo" component={Account} />
            <Route path="/altverification" component={TypeChange} />
            <Route path="/emailverification" component={Verify} />
            <Route path="/eventverification" component={VerifyEvent} />
            <Route path="/seenstl-admin" component={AuthManagement}/>
            <Route path="/password-reset" component={PasswordReset} />
            <Route path="/emailreminder" component={EmailReminder}/>
            <Route path="/protected-page" component={PrivateMode} />
            <Route path="/signupsuccess" component={SignUpSuccess} />
            <Route path="/signInsuccess" component={SignInSuccess} />
            <Route path="/createsuccess" component={CreateSuccess} />
            <Route path="/updatesuccess" component={UpdateSuccess} />  
          </Switch>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
