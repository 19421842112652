import React from 'react'
import EventSummary from './EventSummary'
import moment from 'moment'
import SmallDate from '../../helpers/SmallDate'
import getDayOfWeek from '../../helpers/GetDayOfWeek'
import Add from '../favorites/Add'


const EventList = ({ events, addEmailedFavs, auth, profile }) => {
  
  const hidePastListings = () => {
    //I've included this here for testing but perhaps it should be moved?
    if(events){
      
      const currentDate = new Date().toISOString().slice(0,10);
      let oldDateFilt = events.filter(
        (x) => {
          return x.eventDate > currentDate
        }  
     );
       return oldDateFilt
     
    }
  }
  const filteredEvents = hidePastListings();
  
  return (
    <div id="ev-listings">
      { filteredEvents && filteredEvents.map(event => {
        return (
          <div className="single-listing" key={event.id}>
          <div className="ev-date">
            <span className="ev-dotw">{ getDayOfWeek(event.eventDate) }</span>
            <span className="ev-mmm">{ moment(SmallDate(event.eventDate)).format("MMM") }</span>
            <span className="ev-dd">{ moment(SmallDate(event.eventDate)).format("DD") }</span>
          </div> 
            <EventSummary event={event} auth={ auth } profile={ profile } />  
            <Add eventId={ event.id } addEmailedFavs={ addEmailedFavs } />
          </div>
        )
      })}  
    </div>
  )
}

export default EventList