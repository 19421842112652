import React, { Component } from 'react'
import { removeFavorite } from '../../store/actions/favoriteActions'
import { connect } from 'react-redux'

class Remove extends Component {
  
  //favorites and events states are imported below.
  handleClick = (e) => {
      e.preventDefault();
      const idToRemove = this.props.idToRemove;
      this.props.removeEmailedFavs(idToRemove);
  }
  render() {  
    
    return(
        <div className="fav-delete" onClick={this.handleClick}>
          <a> &#10005; </a>
        </div>  
        )
    }
  }
  const mapStateToProps = (state) => {
      return{
          favorites: state.favorite
      }
  } 

const mapDispatchToProps = () => {
  return {
      removeFavorite
    
  }
}
export default connect(mapStateToProps, mapDispatchToProps())(Remove)
